// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-header-js": () => import("./../../../src/pages/components/Header.js" /* webpackChunkName: "component---src-pages-components-header-js" */),
  "component---src-pages-components-image-js": () => import("./../../../src/pages/components/Image.js" /* webpackChunkName: "component---src-pages-components-image-js" */),
  "component---src-pages-components-last-projects-js": () => import("./../../../src/pages/components/LastProjects.js" /* webpackChunkName: "component---src-pages-components-last-projects-js" */),
  "component---src-pages-components-skills-js": () => import("./../../../src/pages/components/skills.js" /* webpackChunkName: "component---src-pages-components-skills-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/Layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-oriol-hilari-amat-js": () => import("./../../../src/pages/oriol-hilari-amat.js" /* webpackChunkName: "component---src-pages-oriol-hilari-amat-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/Seo.js" /* webpackChunkName: "component---src-pages-seo-js" */)
}

